import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBuildings } from "../slices/Buildings.slice";
import { getCurrentUser } from "../actions/Users.action";
import { db } from '../firebase';
import { ref, onValue, off } from 'firebase/database';

export const useWatchUsers = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const userUid = getCurrentUser()?.uid; // Pobranie UID użytkownika

        if (userUid) {
            const userRef = ref(db, `/users/${userUid}`);
            const eventCallback = snapshot => {
                const value = snapshot.val();
                if (value) {
                    dispatch(setBuildings(Object.values(value)));
                }
            };

            onValue(userRef, eventCallback); // Dodanie nasłuchiwania na zmiany

            return () => {
                off(userRef, 'value', eventCallback); // Usunięcie nasłuchiwania po odmontowaniu komponentu
            };
        }
    }, [dispatch]);

    return null; // Ten hook nie zwraca komponentu
};
