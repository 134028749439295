import { v4 as uuidv4 } from 'uuid';
import { saveCategory } from "../actions/Categories.action"
import { store } from '../store/Main.store';


export const addCategory = async (name) => {
    const { v4: uuidv4 } = require('uuid');
    const defaultCategory = { id: uuidv4(), name, createDate: new Date().toISOString() }
    const state = store.getState();
    const buildingId = state.selectedBuilding
    await saveCategory(buildingId, defaultCategory);
}
