import { configureStore, combineReducers } from '@reduxjs/toolkit';
import categoriesReducer from '../slices/Categories.slice';
import costsReducer from '../slices/Costs.slice';
import selectedBuildingReducer from '../slices/SelectedBuilding.slice';
import selectedCategoryReducer from '../slices/SelectedCategory.slice';
import buildingsReducer from '../slices/Buildings.slice';
import loaderReducer from '../slices/Loader.slice';


const reducer = combineReducers({
    categories: categoriesReducer,
    costs: costsReducer,
    selectedCategory: selectedCategoryReducer,
    selectedBuilding: selectedBuildingReducer,
    buildings: buildingsReducer,
    loader: loaderReducer
})

const store = configureStore({
    reducer: reducer,
});


export { store };
