import CategoryView from '../components/CategoryItem.component';
import LoaderHoc from '../components/Loader.component';
import FileItem from '../components/FileItem.component';
import { useSelector } from 'react-redux';
import '../styles/Build.style.css';

import { updateCost } from '../unitsOfWork/UpdateCost.unitOfWork';
import { addFile } from '../unitsOfWork/AddFile.unitOfWork';
import { removeCost } from '../unitsOfWork/RemoveCost.unitOfWork';
import { openPicker } from '../utils/File.util';
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import CategoryItemComponent from "../components/CategoryItem.component";
import SelectFilePopUpView from "./SelectFilePopUp.view";
import {getCurrentUser} from "../actions/Users.action";

const validator = /^[+-]?\d*(?:[.,]\d*)?$/;


const CostEditView = ({props,route}) => {
    const {id} = useParams();
    const navigate = useNavigate();

    const cost = useSelector(state => state.costs.find(x => x.id === id));
    const [title, setTitle] = useState(cost?.title ?? '')
    const [price, setPrice] = useState(cost?.price ?? '')
    const [description, setDescription] = useState(cost?.description ?? '')
    const [categories, setCategories] = useState(cost?.categories ?? [])
    const allCategories = useSelector(state => state.categories.filter(x => x.id !== 'ALL'))
    const [errorMessage, setErrorMessage] = useState('');
    const [btnPopUpFile, setBtnPopUpFile] = useState(false);




    const handlePrice = (e) => {
        // if (/^\d+$/.test(e.toString())) {
        //   setPrice(e)
        // }
        if (validator.test(e)) {
            e = e.replace(",", ".") //this is optional
            setPrice(e);
        }
        else {
            //this will remove the last character as it didn't succeed validation
            setPrice(e.substring(0, e.length - 1));
        }
    }

    const handleSelectCategory = (status) => {
        if (categories.some(x => x === status.id)) {
            setCategories([...categories.filter(x => x !== status.id)])
        }
        else {
            setCategories([...categories, status.id])
        }
    }


    const save = async () => {

        if (getCurrentUser().email === 'test@investcalc.pl')
        {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }

        else {
        if (title.length > 0 && price.length > 0 && title.trim() && price.trim()) {
            setErrorMessage('');
            await updateCost(cost.id, title, price, description, categories)
            navigate('/')
        }
        else {
            setErrorMessage('Brak wymaganych pól, aby dodać koszt pola tytuł oraz cena muszą zostać uzupełnione');
        }
    }
    }

    const handleDelete = async () => {
        if (getCurrentUser().email === 'test@investcalc.pl')
        {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }
        else {
        await removeCost(cost.id);
        navigate('/')
        }
    }

    const handleClose = () => {
        navigate(-1)
    }

    const handleEnablePopUp = () => {
        // event.defaultValue();
        setBtnPopUpFile(true)
    }



    useEffect(() => {
        if (cost) {
            setTitle(cost.title);
            setPrice(cost.price);
            setDescription(cost.description);
            setCategories(cost?.categories??[]);
        }

    }, [cost]);


    return (
       
        <div className={'main_element_cost'}>
                    <h1 style={{color: 'green', marginBottom:'10px', marginTop:'30px'}}>Edytuj koszt:</h1>


                    <div style={{flexDirection:'column'}}>

                        <div>
                            <h3 style={{color: '#595858'}}>Nazwa kosztu:</h3>
                            <input className={"input_popup"} placeholder='dodaj koszt' value={title} onChange={e=> setTitle(e.target.value)} />
                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Cena:</h3>
                            <input className={"input_popup"} placeholder='dodaj cenę' value={price} onChange={e=> setPrice(e.target.value)}/>
                        </div>

                        <div>
                        <div>
                            <h3 style={{color: '#595858'}}>Kategoria:</h3>
                        </div>

                            <div className={"category_container"}>
                                {allCategories.slice().sort((a, b) => a.createDate > b.createDate ? 1 : -1).map(e => <CategoryItemComponent
                                    key={e.id}
                                    category={e}
                                    isActive={categories.some(x => x === e.id)}
                                    onSelect={handleSelectCategory}
                                />)}
                            </div>

                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Notatka:</h3>
                            <textarea className={"input_cost_add_view_notification"} placeholder='notatka' value={description} onChange={e=> setDescription(e.target.value)} />
                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Dokumentacja:</h3>
                        </div>

                        <div style={{ flexDirection: 'column' }}>

                            <div>
                                <div onClick={() => alert('Doskonalimy jeszcze tą funkcję. Będzie ona dostępna w niedalekiej przyszłości :)')} style={{justifyContent: 'center', alignItems: 'center', backgroundColor:"rgba(46, 166, 28, 0.43)", display:'flex', border:'2px solid green', borderRadius:'7px', cursor:'pointer'}}>
                                    <img src={require('../assets/documentation.png')} style={{ height: 50, marginRight:'5%' }} />
                                    <p style={{fontWeight:'600', color:'white'}}>Dodaj nowy</p>
                                </div>
                            </div>


                            {/*<div style={{ flex: 1, marginRight: 7 }}>*/}
                            {/*    {cost.files &&*/}
                            {/*         <div style={{ paddingTop: 10, marginRight: 3 }}>*/}
                            {/*             {cost.files.map(file =>*/}
                            {/*                 <FileItem key={file.path} file={file} />)}*/}
                            {/*         </div>}*/}
                            {/*</div>*/}
                        </div>

                        <div>
                            <p style={{color: '#B22222' }}>
                                {errorMessage}
                            </p>
                        </div>



                        <div className={'buttons_constainer'}>

                            <div className={'button_save'} onClick={save}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '800'}}>Zapisz</p>
                            </div>

                            <div className={'button_delete'} onClick={handleDelete}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '800'}}>Usuń</p>
                            </div>

                            <div className={'button_cancel'} onClick={handleClose}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '800'}}>Wróć</p>
                            </div>
                        </div>

                    </div>
                </div>
               
    )
}

export default LoaderHoc(CostEditView);

