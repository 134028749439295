import '../styles/AddCategory.style.css';
import '../styles/Build.style.css';
import React from "react";
import {useEffect, useState} from "react";
import { addCategory } from '../unitsOfWork/AddCategory.unitOfWork';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";
import {getCurrentUser} from "../actions/Users.action";



const AddCategoryView = () => {

    const [newCategory, setNewCategory] = useState('');
    const categories = useSelector(state => state.categories)
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleAddCategory = async () => {
      
        if (getCurrentUser().email === 'test@investcalc.pl')
        {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }

        else {
        if (newCategory.length !== 0) {
            if (categories.find(a => a.name === newCategory)) {
                alert('Uwaga, kategoria o takiej nazwie juz isnieje')
            }
            else {
                 await addCategory(newCategory);
                 navigate('/')
            }
        }

        else {
            setErrorMessage('Brak wymaganych pól, wpisz nazwę nowej kategorii');

        }
    }
    }

    return (

            <div className={'main_element_cost'} style={{height:'100vh'}}>
                <h1 style={{color: 'green', marginBottom:'10px', marginTop:'30px'}}>Dodaj kategorię:</h1>
                                

                <h3 style={{ color: '#595858' }}>Nazwa kategorii:</h3>
                <input placeholder={'Kategoria'} className={'input_popup'} value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />

            <div>
                <p style={{ color: '#B22222' }}>
                    {errorMessage}
                </p>
            </div>

            <div style={{marginTop:'2px', marginBottom:'50px'}}>

                <div className={'button_save_input'} onClick={handleAddCategory}>
                    <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '600' }}>Zapisz</p>
                </div>
                </div>
            </div>
    )

}

export default AddCategoryView;
