import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    initialState: '',
    name: 'loader',
    reducers: {
        setIsLoading: (state, { payload }) => {
            return payload
        },
    }
});

export default slice.reducer;

export const { setIsLoading } = slice.actions;