import { store } from "../store/Main.store"
import { saveCost } from "../actions/Costs.action"

export const updateCost = async (id, title, price, description, categories) => {
    const state = store.getState();
    const buildingId = state.selectedBuilding
    const cost = { ...state.costs.find(x => x.id === id) };

    cost.title = title;
    cost.price = price;
    cost.description = description;
    cost.categories = categories;
    cost.updateDate = new Date().toISOString();
    await saveCost(buildingId, cost);
} 