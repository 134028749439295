import React, {useEffect, useState} from "react";
import '../styles/BottomNavigation.style.css';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {Home} from "@mui/icons-material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CoffeeIcon from '@mui/icons-material/Coffee';
import CategoryIcon from '@mui/icons-material/Category';
import {useNavigate} from "react-router-dom";


const BottomNavigationView = () => {
    const navigate = useNavigate();


    return (
        <div className={"bottomNavigationContainer"}>
            <BottomNavigation showLabels>
                <BottomNavigationAction
                    label="Start"
                    icon={<Home />}
                    style={{color:'#686868', fontWeight:'600'}}
                    onClick={() => navigate('/')}
                />
                <BottomNavigationAction
                    label="Produkt"
                    icon={<AddCircleOutlineIcon />}
                    style={{color:'#686868', fontWeight:'600'}}
                    onClick={() => navigate('/add_cost')}
                />
                 <BottomNavigationAction
                    label="Kategoria"
                    icon={<CategoryIcon />}
                    style={{color:'#686868', fontWeight:'600'}}
                    onClick={() => navigate('/add_category')}
                />
                <BottomNavigationAction
                    label="Więcej"
                    icon={<MoreHorizIcon />}
                    style={{color:'#686868', fontWeight:'600'}}
                    onClick={()=> navigate('/menu')}
                />
            </BottomNavigation>

        </div>
    );
};

export default BottomNavigationView;
