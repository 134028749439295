import { v4 as uuidv4 } from 'uuid';
import { store } from "../store/Main.store"
import { saveCost } from "../actions/Costs.action"

export const addCost = async (title, price, description, categories) => {
    const { v4: uuidv4 } = require('uuid');
    const state = store.getState();
    const buildingId = state.selectedBuilding
    const createDate = new Date().toISOString()
    const form = { id: uuidv4(), title, price, description, createDate, categories }

    await saveCost(buildingId, form);

    return form.id;
}
