import { getCurrentUser } from "../actions/Users.action"
// import uuid from 'react-uuid'
import { v4 as uuidv4 } from 'uuid';
import { saveCategory } from "../actions/Categories.action"
import { saveBuilding } from "../actions/Building.action"

export const addBuilding = async (name) => {
    const { v4: uuidv4 } = require('uuid');
    const user = getCurrentUser()
    const building = { name, id: uuidv4(), createDate: new Date().toISOString(), owner: { email: user.email, uid: user.uid } };
    await saveBuilding(user.uid, building)
    const defaultCategory = { id: 'ALL', name: "Wszystkie", createDate: new Date().toISOString() }
    await saveCategory(building.id, defaultCategory);
    return building.id
}
