import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {login} from "../actions/Users.action";
import {auth, loginWithGoogle, provider} from '../firebase';

const LoginView = (props) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClickRegister = () => {
        navigate('/register');
    }

    const handleForgetPasswd = () => {
        navigate('/forget_password');
    }

    const handleClickLogIn = () => {

        if (email.length > 0 , password.length > 0)
        {
            login(email, password)
                .catch(_ => {setErrorMessage('Błąd, sprawdź dane logowania, lub załóz nowe konto jeśli jeszcze go nie posiadasz') })
        }
        else {
            setErrorMessage('Błąd, sprawdź dane logowania, lub załóz nowe konto jeśli jeszcze go nie posiadasz')
        }
    }


    const handleClickLogInDemo = () => {
        
        login("test@investcalc.pl", "gyfvuv-worjuv-2naZdo")

    }


    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
      };


    return (

        <div className="main_view_on_login">
            <div style={{marginTop:'3%'}} className={"main_view_on_login_second"}>

                <div style={{justifyContent:'center', alignItems:'center', textAlign:'center', marginTop:'20px'}}>
                    <img className={"logo_on_login"} src={require("../assets/Logo.png")}/>
                </div>

                <div style={{textAlign:'center', marginBottom:'40px'}}>
                    <h2 style={{marginBottom:'-18px'}}>Zaloguj się</h2>
                    <p>wybierz dogodny dla Ciebie sposób</p>
                </div>
                <div>
                    <h4 style={{color:'#595858'}}>E-mail</h4>
                    <input
                    placeholder={'e-mail'}
                    autoCapitalize={'none'}
                    className="input_popup"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    />
                </div>

                <div>
                <h4 style={{color:'#595858'}}>Hasło</h4>
                    <div style={{flexDirection:'row', display:'flex', position:'relative'}}>
                    <input
                        placeholder={'hasło'}
                        autoCapitalize={'none'}
                        className="input_popup"
                        type={showPassword ? 'text' : 'password'}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                    <div onClick={handleTogglePassword}><img style={{height:'30px', marginTop:'-7px', cursor:'pointer', marginLeft:'-40px'}} src={require('../assets/passwd.png')}/></div>
              
                    </div>
              
                </div>

                <div>
                    <h5 onClick={handleForgetPasswd} className={"forget_password_style"}>Zapomniałeś hasła?</h5>
                </div>


                <div>
                    <h4 style={{color: '#B22222', fontWeight:'500' }}>
                        {errorMessage}
                    </h4>
                </div>


                <div onClick={handleClickLogIn} className={"button_save_input"}>
                    <h4 style={{color:'white', paddingTop:'8px', paddingBottom:'8px', fontWeight:'600'}}>Zaloguj się</h4>
                </div>

                <div style={{textAlign:'center', justifyContent:'center', alignItems:'center', display:'flex', cursor:'pointer'}}>
                    <h4 style={{display:'inline'}} className={"h4_style_login"}>Nie masz konta?</h4>
                    <h4 onClick={() => handleClickRegister()} style={{display:'inline', textDecoration:'underline', marginLeft:'5px'}} className={"h4_style_login"}>Załóż konto</h4>
                </div>

                <div style={{textAlign:'center', justifyContent:'center', alignItems:'center', display:'flex', cursor:'pointer', backgroundColor:'white', border:'1.5px solid green', borderRadius:'10px'}}>
                      <p style={{color:'green', fontWeight:'600'}} onClick={() => handleClickLogInDemo()}>Uruchom demo</p>
                </div>

                <div style={{display:'flex'}}>
                    <div style={{backgroundColor: 'grey', height: 1, flex: 1, alignSelf: 'center'}} />
                    <h5 className={"h4_style_login"} style={{marginLeft:'10px', marginRight:'10px'}}>lub</h5>
                    <div style={{backgroundColor: 'grey', height: 1, flex: 1, alignSelf: 'center'}} />

                </div>

                <div style={{display:'flex', justifyContent:'center', marginBottom:'5%'}}>
                    <button className={'login-with-google-btn'} onClick={loginWithGoogle}>Zaloguj poprzez Google</button>
                </div>
                {/* <div style={{display:'flex', justifyContent:'center'}}>
                    <button className={'login-with-apple-btn'} onClick={props.signInWithApple} > Zaloguj poprzez Apple</button>
                </div> */}
            </div>
        </div>
    )
}

export default LoginView;
