import '../styles/Build.style.css';
import '../styles/Login.style.css';
import CategoryItemComponent from "../components/CategoryItem.component";
import CostItemComponent from "../components/CostItem.component";
import {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {logout} from "../actions/Users.action";
import {useNavigate} from "react-router-dom";
import {getCurrentUser} from "../actions/Users.action";

import { getBuilding } from '../actions/Building.action';
import { select } from '../slices/SelectedCategory.slice';
import { removeCategory } from '../unitsOfWork/RemoveCategory.unitOfWork';
import { useWatchBuilding } from '../hooks/UseWatchBuilding';
import { replaceCosts } from '../slices/Costs.slice';
import { replaceCategories } from '../slices/Categories.slice';


const BuildView = (props) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const categories = useSelector(state => state.categories)
    const buildingId = useSelector(state => state.selectedBuilding);
    const costs = useSelector(state => state.costs);
    const selectedCategory = useSelector(state => state.selectedCategory);

    const [btnPopUp, setBtnPopUp] = useState(false);
    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
    const {input} = props
  
    useWatchBuilding(buildingId);


    const selectedCosts = costs.filter(x => selectedCategory === 'ALL' || (x.categories && x.categories.includes(selectedCategory)));

    const handleSelectCategory = (category) => {
        dispatch(select(category.id));
    }

    const handleDeleteCategory = async (category) => {
        alert('Uwaga❗\nprzypisz koszty usuwanej kategorii do innej, w innym przypadku twoje koszty będą znajdować się w kategorii o nazwie Wszystkie');
            if (category.id === 'ALL') {
                alert("Ta kategoria nie moze zostać usunięta")
            }

            else {
                await removeCategory(category.id)
                dispatch(select('ALL'));

            }
    }

    const signOut = () => {
        logout();
        navigate('/')
    }

    useEffect(() => {
        if (buildingId) {
            getBuilding(buildingId).then(building => {
                const singleVal = building.val();

                if (singleVal) {
                    dispatch(select('ALL'))
                    dispatch(replaceCosts(singleVal?.costs))
                    dispatch(replaceCategories(singleVal?.categories))
                }
            })
        }
    }, [buildingId])




    return (

        <div className={"main_view_build"}>
            
        
                <div style={{flexDirection: 'row'}}>
                    {getCurrentUser().email === 'test@investcalc.pl' ?

                      <div onClick={signOut} style={{cursor:'pointer',backgroundColor:'red', height:'40px', justifyContent:'center', marginTop:'20px' ,alignItems:'center', display:'flex', borderRadius:'10px', paddingLeft:'20px', paddingRight:'20px', marginRight:'5%'}}>
                        <p style={{color:'white', fontWeight:'600'}}>Zakończ wersję demo</p>
                    </div>
                    :
                    null
                    }

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img style={{height: '25px', marginRight: '1%'}} src={require('../assets/dollar.png')}/>
                        <h3 className={'text'}>Poniesione koszty:</h3>
                    </div>

                    <div className={"price_container"}>

                        <div className={"price_view"}>
                            <h1 className={"price"}>{selectedCosts.map(x => parseFloat(x.price)).reduce((a, b) => a + b, 0).toLocaleString("pl-PL")}</h1>
                            <h2 className={"pln"}>zł</h2>
                        </div>
                        <hr/>

                        <div style={{paddingBottom: '2%'}}>
                            <h6 style={{color: 'grey', display: 'inline'}}>koszt całkowity inwestycji:</h6>
                            <h6 style={{color: 'grey', display: 'inline', marginLeft: '2px'}}>{costs
                                .map(x => parseFloat(x.price))
                                .reduce((a, b) => a + b, 0).toLocaleString("pl-PL")}</h6>
                            <h6 style={{color: 'grey', display: 'inline', marginLeft: '2px'}}>zł</h6>
                        </div>

                    </div>

                    <div className={'category_and_products_container_mobile'}>

                        <div className={'category_and_products_name'}>
                            <img style={{height: '22px', marginRight: '1%'}} src={require('../assets/categories.png')}/>
                            <h3 className={'text'}>Kategorie:</h3>
                        </div>

                    </div>

                    <div className={'category_and_products_container_desktop'}>

                        <div className={'category_and_products_name'}>
                            <img style={{height: '22px', marginRight: '1%'}} src={require('../assets/categories.png')}/>
                            <h3 className={'text'}>Kategorie:</h3>
                        </div>
                       
                    </div>

                    <div className={"category_container"}>
                    {categories.slice().sort((a, b) => a.createDate > b.createDate ? 1 : -1).map(e => <CategoryItemComponent
                        key={e.name} category={e}
                        isActive={selectedCategory === e.id}
                        onSelect={handleSelectCategory}
                        onDelete={handleDeleteCategory}
                    />)}
                    </div>
            </div>

                <div className={'category_and_products_container_mobile'}>

                    <div className={'category_and_products_name'}>
                        <img style={{height:'22px', marginRight:'1%'}} src={require('../assets/features.png')}/>
                        <h3 className={'text'}>Produkty:</h3>
                    </div>

                </div>

                <div className={'category_and_products_container_desktop'}>

                    <div className={'category_and_products_name'}>
                        <img style={{height:'22px', marginRight:'1%'}} src={require('../assets/features.png')}/>
                        <h3 className={'text'}>Produkty:</h3>
                    </div>

                    {/* <div onClick={toggleBottomSheet} className={'category_and_products_elements'}>
                        <div className={'category_and_products_button'}>
                            <img src={require('../assets/plus.png')} height={22}/>
                            <p style={{marginLeft:'6%'}}>Dodaj produkt</p>
                        </div>
                    </div> */}

                </div>


            <div className={"products_container"}>
               { selectedCosts && selectedCosts.length > 0 ? selectedCosts.sort((a, b) => a.createDate < b.createDate ? 1 : -1).map(cost =>

                    <CostItemComponent
                        id={cost.id}
                        title={cost.title}
                        price={cost.price}
                        description={cost.description}
                        createDate={cost.createDate}
                        updateDate={cost.updateDate}
                        key={cost.id} />) : <h2 style={{ color: 'gray', fontWeight: 400, textAlign: "center" }}>Brak kosztów</h2>
                


                }
            </div>


        </div>
    )
}

export default BuildView;

