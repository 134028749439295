import '../styles/TopMenu.style.css';
import {getCurrentUser} from "../actions/Users.action";
import MenuNavView from './MenuNav.view';
import {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";


const TopMenu = (props) => {
   
    const [navbarOpen, setNavbarOpen] = useState(false);
    const navigate = useNavigate();
    const [showSearch, setShowSearch] = useState(false);
    const {setInput} = props;


    useEffect(() => {
        document.addEventListener("click", handleClickOutisde, true)
    },[])
    
    const refOne = useRef(null);

    const handleClickOutisde = (e) => {

            if (!refOne.current.contains(e.target)) {
                setNavbarOpen(false)
            }
            else {
               setNavbarOpen(true)   
            }
    }



    const handleOpenSearchView = () => {
        setShowSearch(!showSearch)

        if(showSearch === true)
        {
            navigate('/')
            setInput('')
        }

        else {
            navigate('/search')
        }
        
    }

   

    return (
        
        <div>
        
        <div className={'top_menu_main_desktop'}>
                <div style={{flex:0.2, justifyContent:'center', alignItems:'center', display:'flex'}} onClick={() => navigate('/')}>
                    <img style={{width:'150px', justifyContent:'center', alignItems:'center'}} src={require('../assets/LogoTop.png')}/>

                    
                </div>
              


                <div style={{display:'flex', flex:0.8, justifyContent:'right', alignItems:'center'}}>
                
            
                    <div onClick={() => setNavbarOpen((prev) => !prev)} style={{alignItems: 'center', cursor: 'pointer', display:'flex' }}>
                      
                      {
                      getCurrentUser().email === 'test@investcalc.pl' ?
                            <h4 style={{ color: 'white', marginRight: '10px' }}>Demo</h4>
                            :
                            <h4 style={{ color: 'white', marginRight: '10px' }}>{getCurrentUser().email}</h4>
                      } 

                        <img style={{ width: '35px' }} src={require('../assets/account.png')} />
                    </div>

                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', cursor:'pointer'}} onClick={() => navigate('/search')}>
                        <img style={{ height: '40px', marginRight: '5px', marginLeft: '40px' }} src={require('../assets/Lupa_white.png')} />
                    </div>

                </div>
        </div>

            <MenuNavView refOne={refOne} navbarOpen={navbarOpen} />

        <div className={'top_menu_main_mobile'}>
            
             <div style={{justifyContent:'center', alignItems:'center', display:'flex', flex:0.9}}>
            
                    <img style={{width:'150px', justifyContent:'center', alignItems:'center'}} src={require('../assets/LogoDark.png')} onClick={() => navigate('/')}/>
             </div>

             <div style={{alignItems:'center', justifyContent:'center', display:'flex'}} onClick={() => navigate('/search')}>
                <img style={{ height: '40px' }} src={require('../assets/search.png')} />
            </div>
        </div>

        </div>
    )
}

export default TopMenu;