import {useSelector } from 'react-redux';
import CostItemComponent from "../components/CostItem.component";
import {useState, useEffect, useRef} from "react";



const Search = (props) => {

    const costs = useSelector(state => state.costs);
    const selectedCategory = useSelector(state => state.selectedCategory);
    const {input, setInput} = props

    const selectedCosts = costs.filter(x => selectedCategory === 'ALL' || (x.categories && x.categories.includes(selectedCategory)));

    const handleSearchElement = (value) => {
        setInput(value)
    }

    useEffect(() => {
        setInput('')
    },[])
  
    return (
        <div className={"main_view_build"}>

            <div style={{flexDirection:'row'}}>
           
            <div className={'category_and_products_name'}>
                <img style={{ height: '33px', marginRight: '1%' }} src={require('../assets/search_black.png')} />
                <h2 className={'text'}>Szukaj:</h2>
            </div>

            <div style={{justifyContent:'center', alignItems:'flex-start', display:'flex', marginBottom:'20px'}}>
                
            <input onChange={(e) => handleSearchElement(e.target.value)} style={{marginBottom:'10px',borderRadius:'5px', height:'35px', width:'300px', border:'1px solid green', fontWeight:'400', fontSize:'18px' ,paddingLeft:'10px', width:'100%'}} placeholder='Nazwa produktu'/>
            </div>
      

            <div className={"products_container"}>

            {input.length !== 0 ?

                selectedCosts && selectedCosts.length > 0 ? selectedCosts.sort((a, b) => a.createDate < b.createDate ? 1 : -1).filter((item) => item.title.toLowerCase().includes(input.toLowerCase()) || item.description.toLowerCase().includes(input.toLowerCase()) || item.price.toLowerCase().includes(input.toLowerCase()) ).map(cost =>

                    <CostItemComponent
                        id={cost.id}
                        title={cost.title}
                        price={cost.price}
                        description={cost.description}
                        createDate={cost.createDate}
                        updateDate={cost.updateDate}
                        key={cost.id} />) : <h2 style={{ color: 'gray', fontWeight: 400, textAlign: "center" }}>Brak kosztów</h2>


                :

                selectedCosts && selectedCosts.length > 0 ? selectedCosts.sort((a, b) => a.createDate < b.createDate ? 1 : -1).map(cost =>

                    <CostItemComponent
                        id={cost.id}
                        title={cost.title}
                        price={cost.price}
                        description={cost.description}
                        createDate={cost.createDate}
                        updateDate={cost.updateDate}
                        key={cost.id} />) : <h2 style={{ color: 'gray', fontWeight: 400, textAlign: "center" }}>Brak kosztów</h2>
            }
            </div>
            </div>
        </div>

    )
}


export default Search;