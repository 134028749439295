import '../styles/Bottom_sheet.style.css';
import '../styles/CostAddView.style.css';
import '../styles/Build.style.css';
import '../styles/AddCategory.style.css'
import React, {useState} from "react";
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {addCost} from "../unitsOfWork/AddCost.unitOfWork";
import CategoryItemComponent from "../components/CategoryItem.component";
import {getCurrentUser} from "../actions/Users.action";


const AddCost = () => {

    const [title, setTitle] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')
    const [categories, setCategories] = useState([])
    const allCategories = useSelector(state => state.categories.filter(x => x.id !== 'ALL'))
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


    const handleTitle = (e) => {
        setTitle(e.target.value)
        if (price) {
            setErrorMessage('');
        }
    }

    const handlePrice = (e) => {
        setPrice(e.target.value)
    }

    const handleDescription = (e) => {
        setDescription(e.target.value)
    }

    const save = async () => {

        if (getCurrentUser().email === 'test@investcalc.pl')
        {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }

        else {

            if (title.length > 0 && price.length > 0 && title.trim() && price.trim()) {
                setErrorMessage('');
                await addCost(title, price, description, categories)
                navigate('/')
            }

            else {
                setErrorMessage('Brak wymaganych pól, aby dodać koszt pola tytuł oraz cena muszą zostać uzupełnione');
            }
        }
    }

    const saveAddFile = async () => {
        // if (title.length > 0 && price.length > 0 && title.trim() && price.trim()) {
        //     setErrorMessage('');
        //     const costId = await addCost(title, price, description, categories)
        // }
        // else {
        //     setErrorMessage('Brak wymaganych pól, aby dodać koszt pola tytuł oraz cena muszą zostać uzupełnione');
        // }
        alert('Doskonalimy jeszcze tą funkcję. Będzie ona dostępna w niedalekiej przyszłości :)')
    }


    const handleToggleCategory = (status) => {
        if (categories.some(x => x === status.id)) {
            setCategories([...categories.filter(x => x !== status.id)])
        }
        else {
            setCategories([...categories, status.id])
        }
    }



    return (
          

        <div className={'main_element_cost'}>

                    <h1 style={{color: 'green', marginBottom:'20px', marginTop:'30px'}}>Dodaj koszt:</h1>
                    <div style={{flexDirection:'column'}}>

                        <div>
                            <h3 style={{color: '#595858'}}>Nazwa kosztu:</h3>
                            <input className={"input_popup"} placeholder='dodaj koszt' value={title} onChange={handleTitle} />
                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Cena:</h3>
                            <input type={"number"} className={"input_popup"} placeholder='dodaj cenę' value={price} onChange={handlePrice}/>
                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Kategoria:</h3>
                        </div>

                        <div className={"category_container"}>
                            {allCategories.slice().sort((a, b) => a.createDate > b.createDate ? 1 : -1).map(e => <CategoryItemComponent
                                key={e.id}
                                category={e}
                                isActive={categories.some(x => x === e.id)}
                                onSelect={handleToggleCategory}
                            />)}
                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Notatka:</h3>
                            <textarea className={"input_cost_add_view_notification"} placeholder='notatka' value={description} onChange={handleDescription} />
                        </div>


                        <div>
                            <p style={{color: '#B22222' }}>
                                {errorMessage}
                            </p>
                        </div>
                           
                    

                        <div className={'buttons_constainer'}>

                            <div className={'button_save_input'} onClick={save}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '600'}}>Zapisz</p>
                            </div>

                            <div className={'button_save_input'} onClick={saveAddFile}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '600'}}>Zapisz i dodaj fakturę</p>
                            </div>
                        </div>

                    </div>
                </div>
                  

    )
}

export default AddCost;