import { createSlice } from '@reduxjs/toolkit';
const categoriesSlice = createSlice({
    name: 'categories',
    initialState: [],
    reducers: {
        replaceCategories: (state, { payload }) => {
            return payload ? Object.values(payload) : []
        }
    }
});

export const { replaceCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;