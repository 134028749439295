import './App.css';
import './styles/Build.style.css'

import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";

import LoginView from "./views/Login.view";
import RegisterView from "./views/Register.view";
import PrivacyInfoView from "./views/PrivacyInfo.view";
import ForgetPasswordView from "./views/ForgetPassword.view";
import BuildView from "./views/Build.view";
import SplashView from './views/Splash.view';

import {useState, useEffect, useRef} from "react";
import { Provider } from 'react-redux'; // Import Provider z react-redux
import {store} from "./store/Main.store";
import {auth, loginWithGoogle, signInWithApple} from './firebase';
import CostEditView from "./views/CostEdit.view";
import MenuNavView from "./views/MenuNav.view";
import { getBuildings } from './actions/Building.action';
import { setBuildings } from './slices/Buildings.slice';
import {select as selectBuilding} from './slices/SelectedBuilding.slice';
import { addBuilding } from './unitsOfWork/AddBuilding.unitOfWork';
import TopMenu from './views/TopMenu.view';
import BottomNavigationView from './views/BottomNavigation.view';
import LeftContainer from "./views/LeftContainer.view.js";
import AddCategoryView from './views/AddCategoryView.view.js';
import AddCost from './views/AddCost.view.js';
import MenuNavMobileView from './views/MenuNavMobile.view.js';
import Search from './views/Search.view.js';
import ShowSingleProductView from './views/ShowSingleProduct.view.js';


function App() {

    const [user, setUser] = useState();
    const [isSplash, setIsSplash] = useState(true);
    const [input, setInput] = useState('')
   

    // Handle user state changes
    function onAuthStateChanged(authUser) {
        setUser(authUser);

        if (authUser) {
            getBuildings(authUser.uid).then(async building => {
                if (building.val()) {
                    const buildings = Object.values(building.val()).sort((a, b) => a.createDate > b.createDate ? 1 : -1)
                    store.dispatch(setBuildings(buildings))
                    store.dispatch(selectBuilding(buildings[0].id))
                }
                else {
                    const buildingId = await addBuilding('Moja budowa')
                    store.dispatch(selectBuilding(buildingId))
                }
            })
        }
    }

    useEffect(() => {
        // Wait for 3 seconds
        setTimeout(() => {
            setIsSplash(false);
        }, 2000);
    }, []);


     useEffect(() => {
         const subscriber = auth.onAuthStateChanged(onAuthStateChanged)
        return subscriber; // unsubscribe on unmount
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    

 

    if(!user)
    return (
    <div>
         {isSplash ?
             <Routes>
                 <Route path="/" element={<SplashView/>}/>
             </Routes>
             :
             <Routes>
                 <Route path="/" element={<LoginView loginWithGoogle={loginWithGoogle}/>}/>
                 <Route path="register" element={<RegisterView/>}/>
                 <Route path="privacy_info" element={<PrivacyInfoView/>}/>
                 <Route path="forget_password" element={<ForgetPasswordView/>}/>
            </Routes>
         }
    </div>
  );

    else {
        return (
            <div>           
                {isSplash ?
                    <Routes>
                        <Route path="/" element={<SplashView/>}/>
                    </Routes>
                    :
                            <div style={{position:'absolute', margin:'0px', overflow:'hidden', top:'0px', bottom:'0px', width:'100%'}}>
                                <TopMenu />

   
                                <div className={'main_container'}>

        

                            <div className={'header_main_container'}>

                                <div className={'left_menu_on_desktop'}>
                                    <LeftContainer />
                                </div>

                                <div className={'right_menu_on_desktop'}>
                                    <Routes>
                                        <Route path={'/'} element={<BuildView/>} />
                                        <Route path='add_cost' element={<AddCost />} />
                                        <Route path={'add_category'} element={<AddCategoryView />} />
                                        <Route path={'/show_single_product/:id'} element={<ShowSingleProductView/>} />
                                        <Route path={'/edit_products/:id'} element={<CostEditView />} />
                                        <Route path={'menu_nav'} element={<MenuNavView />} />
                                        <Route path={'menu'} element={<MenuNavMobileView />} />
                                        <Route path={'search'} element={<Search setInput={setInput} input={input}/>} />
                                    </Routes>
                                </div>
                            </div>

            
                                    <div>
                                        <BottomNavigationView />
                                    </div>
                                </div>
                            </div>
                }

            </div>
        )
    }


}

export default App;
