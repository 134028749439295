import {getCurrentUser, logout} from "../actions/Users.action";
import {removeBuilding} from "../unitsOfWork/RemoveBuilding.unitOfWork";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addBuilding} from "../unitsOfWork/AddBuilding.unitOfWork";
import {select} from "../slices/SelectedBuilding.slice";
import {select as selectCategory} from "../slices/SelectedCategory.slice";
import {useWatchUsers} from "../hooks/UseWatchUsers";
import BottomNavigationView from "./BottomNavigation.view";
import {useNavigate} from "react-router-dom";

const MenuNavMobileView = (props) => {

   
    const buildings = useSelector(state => state.buildings)
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const signOut = () => {
        logout();
        navigate('/')
    }



    const handleAddBuilding = async () => {
       

        const name = prompt('Dodaj nazwę nowej inwestycji:')
        
        if (getCurrentUser().email === 'test@investcalc.pl')
        {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }

        else {
            if (name.length !== 0) {
                await addBuilding(name)
            }
        }
    }

    const selectBuilding = (id) => () => {
        dispatch(select(id));
        dispatch(selectCategory('ALL'))
        // setNavbarOpen(false)
        navigate('/')


    };
    useWatchUsers();



    return (
         <div className={'main_element_cost'} style={{height:'100vh'}}>
        
          
        <h1 style={{color: 'green', marginBottom:'10px', marginTop:'30px'}}>Witaj,</h1>
            
                {
                      getCurrentUser().email === 'test@investcalc.pl' ?
                      <h3 style={{marginTop:'-2px' ,color: '#595858' }}>Demo</h3>
                      :
                            <h3 style={{marginTop:'-2px' ,color: '#595858' }}>{getCurrentUser().email}</h3>
                        } 

                <div>
                    <div style={{flex:1, display:'flex'}}>
                        <div style={{flex:0.7}}>
                            <h3 style={{color: '#595858'}}>Moje inwestycje</h3>

                        </div>
                        <div style={{flex:0.3, justifyContent:"right",alignItems:"center", textAlign:"center", display:"flex"}}>
                            <img src={require('../assets/add.png')} height={20} onClick={handleAddBuilding}/>
                        </div>
                    </div>

                    <hr style={{marginTop:'-10px', marginBottom:'20px'}}/>

                    {/*THIS*/}
                {buildings &&
                    <div>
                        {buildings.slice().sort((a, b) => a.createDate > b.createDate ? 1 : -1).map(building =>

                            <div key={building.id} style={{ flex: 1, flexDirection:'row', display:'flex', justifyContent:'center',alignItems: "center", backgroundColor:'white', paddingLeft:'15px', paddingRight:'15px', borderRadius:'8px', marginBottom:'10px'}}>

                                <div style={{ flex: 0.7, flexDirection: "row" }} onClick={selectBuilding(building.id)}>
                                    <h4 style={{ color: 'green', fontWeight:'600' }}>{building.name}</h4>
                                </div>

                                <div style={{ flex: 0.3, justifyContent: "right", alignItems: "center", textAlign: "center", display: "flex" }}
                                    onClick={
                                        async () => {

                                            if (getCurrentUser().email === 'test@investcalc.pl')
                                            {
                                                alert('Ta funkcja nie jest dostępna w wersji demo')
                                            }
                                    
                                            else {
                                            if (buildings.length > 1) {
                                                await removeBuilding(building.id);
                                                const id = buildings.filter(x => x.id !== building.id)[0].id;
                                                selectBuilding(id)()
                                            }
                                            else { alert('Nie można usunać inwestycji, przynajmniej jedna inwestycja musi istnieć'); }
                                            }
                                            }
                                    }
                                >
                                    <img src={require('../assets/trash.png')} style={{ height: 18, width: 15 }} />
                                
                                </div>
                            
                            </div>
                            
                        )}
                  
                    </div>
                }
                </div>


                <div style={{bottom:'70px', justifyContent:'center', alignItems:'center', flexDirection:'column', position:'fixed', display:'flex', width:'90%'}}>
                    {/* <a style={{textDecoration:"none", color:"gray", fontSize:'20px'}} href='https://app.investcalc.pl/Instrukcja_iPhone.png'>InvestCalc jako aplikacja na iOS</a>
                    <hr style={{border:'1px solid #D0D0D0', width:'70%'}}/>
                    <a style={{textDecoration:"none", color:"gray", fontSize:'20px'}} href='https://www.facebook.com/InvestCalc/'>Propozycje zmian</a>
                    <hr style={{border:'1px solid #D0D0D0', width:'70%'}}/> */}
                    <a style={{textDecoration:"none", color:"gray", fontSize:'20px'}} href='mailto: pomoc@investcalc.pl'>Pomoc techniczna</a>
                    
                    <h3 style={{color:'#880808', cursor:'pointer'}} onClick={signOut}>Wyloguj</h3>
                </div>

            </div>
            
          )
}

export default MenuNavMobileView;
