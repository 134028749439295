import '../styles/CostItem.style.css';
import {useState} from "react";
import {useNavigate, Link} from "react-router-dom";

const CostItemComponent = (props) => {

    const [showMore, setShowMore] = useState(false)
    const { id, title, price, description, createDate, updateDate} = props;
    const navigate = useNavigate();

    const handleToggleMore = () => {
        setShowMore(!showMore)
    }



    return (
        <div>
            <div className={"cost_container_single_element"}>

                <div style={{flex:1, display:"flex" ,flexDirection:'row'}}>

                    <Link to={`/show_single_product/${id}`} style={{flex:0.6, textDecoration:'none'}}>

                    <h3 style={{color:'grey', marginLeft:'4%', fontWeight:'500'}}>{title}</h3>
                    </Link>

                    <div style={{flex:0.3, textAlign:"right", marginRight:'4%'}}>
                        <h3 style={{color:'green', fontWeight:'400'}}>{price.toLocaleString()} zł</h3>
                    </div>

                    <div style={{flex:0.1, textAlign:'center', alignItems:"center", display:'flex', cursor:'pointer'}} onClick={handleToggleMore}>
                        {showMore ?
                                <img style={{height: '22px', transform: "rotate(180deg)"}}
                                     src={require('../assets/arrow.png')}/>
                                :
                                <img style={{height: '22px', transform: "rotate(0deg)"}}
                                     src={require('../assets/arrow.png')}/>
                        }
                    </div>

                </div>

                <div>
                    {showMore &&
                        <div style={{flex:1, paddingBottom:'20px'}}>
                            <div style={{marginLeft:'2%'}}>
                                <h4 style={{color:'#404040', fontWeight:'300', marginBottom:'-10px'}}>Notatka:</h4>
                                {description.length > 0 ?
                                    <p style={{ color: 'grey'}}>{description}</p> : <p style = {{ color:'grey'}}>brak notatek</p>}
                                    <hr/>
                                {(createDate || updateDate) && <div>
                                    <p style={{color:'#404040', fontWeight:'300', marginBottom:'-10px'}}>{updateDate ? 'Zaktualizowano: ' : 'Dodano: '}</p>
                                    <p style={{ color: 'grey' }}>{updateDate?.slice(0,10) || createDate?.slice(0,10)}</p>
                                </div>}
                            </div>

                        </div>
                    }
                </div>
            </div>
            {/*<div className={'hr_element'}>*/}
            {/*    <hr style={{width:'95%'}}/>*/}
            {/*</div>*/}
        </div>
    )
}

export default CostItemComponent;


