import {auth} from "../firebase";
import {sendPasswordResetEmail, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword} from 'firebase/auth'


export const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
}

export const logout = () => {
    return signOut(auth)
}

export const register = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
        // .createUserWithEmailAndPassword(email, password);
}
export const getCurrentUser = () => auth.currentUser

export const forgetPassword = (email) => {
    // return auth.sendPasswordResetEmail(email)
        return sendPasswordResetEmail(auth, email)

    }
