import { createSlice } from '@reduxjs/toolkit';

const buildingsSlice = createSlice({
    name: 'buildings',
    initialState: [],
    reducers: {
        setBuildings: (_, { payload }) => {
            return payload;
        }
    }
});

export const { setBuildings } = buildingsSlice.actions;
export default buildingsSlice.reducer;