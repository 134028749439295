import { initializeApp } from 'firebase/app';
import {getAuth, GoogleAuthProvider, signInWithRedirect, signInWithPopup} from 'firebase/auth';
import {getDatabase} from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAUDZEQCcuQFH0JXy8yFXUEWD55Cadp7-8",
    authDomain: "investcalc-3f882.firebaseapp.com",
    databaseURL: "https://investcalc-3f882-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "investcalc-3f882",
    storageBucket: "investcalc-3f882.appspot.com",
    messagingSenderId: "298146598993",
    appId: "1:298146598993:web:66fc68979d1d02b9c9e870",
    measurementId: "G-8ZJBPEZQ3C"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getDatabase(app)
const provider = new GoogleAuthProvider();



export const loginWithGoogle = () => {
    signInWithPopup(auth,provider)
    .then((data) => console.log(data))

}



// var provider = new firebase.auth.GoogleAuthProvider();
// firebase.auth().signInWithRedirect(provider);

export default app;




