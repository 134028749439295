import {useState} from "react";
import '../styles/NavBat.style.css';
import '../styles/LeftContainer.style.css';
import {logout, getCurrentUser} from "../actions/Users.action";
import { useDispatch, useSelector } from 'react-redux';
import { select } from '../slices/SelectedBuilding.slice';
import { select as selectCategory } from '../slices/SelectedCategory.slice';
import { addBuilding } from '../unitsOfWork/AddBuilding.unitOfWork';
import { removeBuilding } from '../unitsOfWork/RemoveBuilding.unitOfWork';
import {Link, useNavigate} from "react-router-dom";


const NavBarComponent = () => {
    // const [navbarOpen, setNavbarOpen] = useState(false);
    const buildings = useSelector(state => state.buildings)
    const dispatch = useDispatch();
    const [tmpUserName, setTmpUserName] = useState('');

    const navigate = useNavigate();


    const signOut = () => {
        logout();
    }



    const handleAddBuilding = async () => {
        const name = prompt('Dodaj nazwę nowej inwestycji:')

        if (name.length !== 0) {
            await addBuilding(name)
        }

    }

    const selectBuilding = (id) => () => {
        dispatch(select(id));
        dispatch(selectCategory('ALL'))
        // setNavbarOpen(!navbarOpen)

    };


    return (
        <div style={{padding:'5%', width:'100%', height:'100vh'}}>
           
            <div className={'left_container_category'}>
                <img style={{width:'23px', height:'23px'}} src={require('../assets/home.png')} />
                <h4 style={{cursor:'pointer'}} className={'title_text'} onClick={() => navigate('/')}>Start</h4>
            </div>
            <hr/>

            <div className={'left_container_category'}>
                <img style={{width:'21px', height:'21px'}} src={require('../assets/money.png')} />
                <h4 className={'title_text'}>Koszty</h4>  
            </div>

            <div style={{flexDirection:'row', marginTop:'-20px' }}>
                    <p onClick={() => navigate('add_cost')} className={'sub_title_text'}>- Dodaj koszt</p>
                    <p onClick={() => navigate('add_category')} className={'sub_title_text'}>- Dodaj kategorię</p>
            </div>
            <hr/>

            <div className={'left_container_category'}>
                <img style={{width:'21px', height:'21px'}} src={require('../assets/icon_account.png')} />
                <h4 className={'title_text'}>Konto</h4>
               
            </div>
            <div style={{flexDirection:'row', }}>
                    <p onClick={() => signOut()} className={'sub_title_text'}>- Wyloguj się</p>
            </div>
            <hr/>

            <div className={'left_container_category'}>
                <img style={{width:'21px', height:'21px'}} src={require('../assets/help.png')} />
                <h4 className={'title_text'}>Pomoc</h4>
               
            </div>
            <div style={{ flexDirection: 'row', }}>
                <Link to='javascript:void(0)'
                    onClick={() => window.location = 'mailto:pomoc@investcalc.pl'}
                    style={{textDecoration:'none'}}
                    >
                    <p className={'sub_title_text'}>- pomoc@investcalc.pl</p>
                </Link>
            </div>
    

        </div>
        
    )
}

export default NavBarComponent;
