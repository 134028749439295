import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {register} from "../actions/Users.action";

const RegisterView = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('');

    const [isPrivacyInforChecked,setIsPrivacyInforChecked] = useState(false);
    const [wrongPassword, setWrongPassword] = useState(true)
    const [wrongEmail, setWrongEmail] = useState(true)

    const [wrongRepeatPassword, setWrongRepeatPassword] = useState(true)


    const handleStatement = () => {
        navigate('/privacy_info')
    }

    const handleGoBack = () => {
        navigate(-1)
    }


    const SignUp = () => {

        if (password.length > 0 && email.length > 0 && repeatPassword.length > 0 && isPrivacyInforChecked === true)
        {
            if (password === repeatPassword) {
                register(email, password)
                    .catch(error => {
                        if (error.code === 'auth/email-already-in-use') {
                            alert('Ten adres e-mail jest już w użyciu!')
                        }

                        if (error.code === 'auth/invalid-email') {
                            setWrongEmail(false)

                        }

                        if (error.code === 'auth/weak-password') {
                            setWrongPassword(false)

                        }
                    })
            }

            else {
                setWrongRepeatPassword(false)
            }
        }

        else {
            alert('Brak danych do rejestracji. Wprowadź w odpowienie pola dane, aby założyć konto w aplikacji InvestCalc')
        }
    }


    return(
        <div className="main_view_on_login">
            <div style={{marginTop:'3%'}} className={"main_view_on_login_second"}>

          
            <div onClick={handleGoBack} style={{display: 'flex', alignItems: 'center', flex: 1, width: '100%', height: '90px', cursor:'pointer'}}>
                    <img style={{height: '18px'}} src={require('../assets/new_arrow.png')}/>
                    <p style={{color: 'dimgrey', marginLeft: '2px'}}>Wróć</p>
                </div>



            <div style={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                <img className={"logo_on_login"} src={require("../assets/Logo.png")}/>
            </div>

            <div style={{textAlign:'center'}}>
                <h2 style={{marginBottom:'-1%'}}>Zarejestruj się</h2>
                <p>Podaj swoje dane, którymi będziesz się logować, aby w pełni korzystać z aplikacji</p>
            </div>

            <div>
            <h4 style={{marginBottom:'15px', color:'#595858'}}>E-mail</h4>
                <input
                    placeholder={'e-mail'}
                    autoCapitalize={'none'}
                    className="input_popup"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                />
            </div>
            {wrongEmail ? null : <p style={{color: '#B22222'}}>e-mail jest nieprawidłowy</p>}


            <div>
             <h4 style={{marginBottom:'15px', color:'#595858'}}> Hasło</h4>
                <input
                    placeholder={'hasło'}
                    autoCapitalize={'none'}
                    className="input_popup"
                    type={"password"}
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                />
            </div>
            {wrongPassword ? null : <p style={{color: '#B22222' }}>hasło jest zbyt krótkie, min 6 znaków</p>}


            <div>
                <h4 style={{marginBottom:'15px', color:'#595858'}}>Powtórz hasło</h4>
                <input
                    placeholder={'powtórz hasło'}
                    autoCapitalize={'none'}
                    className="input_popup"
                    type={"password"}
                    onChange={e => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                />
            </div>

            {wrongRepeatPassword ? null : <p style={{color: '#B22222' }}>hasła nie są indentyczne</p>}


            <div style={{display:'flex'}}>
                <input checked={isPrivacyInforChecked}  onChange={() => setIsPrivacyInforChecked(!isPrivacyInforChecked)} style={{marginRight:'10px', marginTop:'20px', width:'30px', height:'30px', accentColor:'green'}} type="checkbox"/>
                <p style={{display:'inline'}} onClick={handleStatement}>Oświadczam, że zapoznałem się z polityką prywatności oraz przetwarzanie danych osobowych - <p style={{color:'green', display:'inline', textDecoration:'underline', cursor:'pointer'}}>przeczytaj</p></p>
            </div>

            {isPrivacyInforChecked ? null : <p style={{color: '#B22222', textAlign:'center' }}>Potwierdź przeczytanie powyższego oświadczenia</p>}



            <div className={"login_press_button"}>
                <h4 onClick={SignUp} style={{color:'white', paddingTop:'8px', paddingBottom:'8px', fontWeight:'600'}}>Załóż konto</h4>
            </div>

            </div>
        </div>
    )
}

export default RegisterView;
