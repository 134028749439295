import React, {useEffect, useState} from "react";



const LoaderHoc = (Component) => {
    return function WithLoader(props) {
        const [isLoading, setIsLoading] = useState(false);

        return isLoading ?
            // Tutaj możesz dodać komponent loader który się wyświetli przy dodawaniu lub pobieraniu pliku
            <div style={{flex:1,justifyContent:'center', alignContent:'center', alignItems:'center'}}><p style={{color:'green'}}>Ładowanie ...</p></div>
            :
            <Component {...props} setIsLoading={setIsLoading}></Component>
    }
}

export default LoaderHoc;
