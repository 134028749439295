import { removeCategory as removeCategoryAction } from "../actions/Categories.action"
import { saveCosts } from "../actions/Costs.action";
import { store } from '../store/Main.store'

export const removeCategory = async (categoryId) => {
    const state = store.getState();
    const buildingId = state.selectedBuilding
    const costs = {}
    state.costs.forEach(c => {
        const cost = { ...c };
        if (cost.categories && cost.categories.includes(categoryId)) {
            cost.categories = [...cost.categories.filter(x => x !== categoryId)]
        }
        costs[cost.id] = cost
    });

    await saveCosts(buildingId, costs)

    await removeCategoryAction(buildingId, categoryId);
} 