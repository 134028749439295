import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const PrivacyInfoView = () => {
    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1)
    }




    return (
        <div className="main_view_on_login">
            <div  tyle={{marginTop:'3%'}} className={"main_view_on_login_second"}>

            
            
            <div onClick={handleGoBack} style={{display: 'flex', alignItems: 'center', flex: 1, width: '100%', height: '90px', cursor:'pointer'}}>
                    <img style={{height: '18px'}} src={require('../assets/new_arrow.png')}/>
                    <p style={{color: 'dimgrey', marginLeft: '2px'}}>Wróć</p>
                </div>

                <div style={{ marginLeft: 20, marginTop: 10, marginRight: 20 }}>
                    <p style={{ fontSize: 19, fontWeight: '500', textAlign: 'center', color:'grey' }}>Polityka prywatności oraz przetwarzanie danych osobowych w aplikacji mobilnej InvestCalc </p>
                </div>
                <div style={{ marginLeft: 14, marginTop: 40, marginRight: 14 }}>
                    <p style={{ fontSize: 13, fontWeight: '500', color:'grey' }}>I. Przetwarzanie danych osobowych użytkowników aplikacji mobilnych</p>
                </div>
                <div style={{ marginLeft: 14, marginTop: 10, marginRight: 14 }}>
                    <p style={{ fontSize: 11, fontWeight: '400', color:'grey' }}>1. Dane dotyczące użytkowników aplikacji mobilnych przetwarzane są przez iTech Mateusz Szmolke, NIP 9910523981, REGON 381917884, będącą w odniesieniu do danych osobowych użytkowników administratorem danych osobowych w rozumieniu ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych</p>
                </div>
                <div style={{ marginLeft: 14, marginTop: 10, marginRight: 14 }}>
                    <p style={{ fontSize: 11, fontWeight: '400', color:'grey' }}>2. Zbierane przez iTech Mateusz Szmolke dane są przechowywane na platformie Firebase, a ich treść nie będzię udostępniana osobom postronnym. </p>
                </div>
                {/* <View style={{ marginLeft: 14, marginTop: 10, marginRight: 14 }}>
                    <Text style={{ fontSize: 11, fontWeight: '400' }}>3. Każdemu użytkownikowi, który wypełnił formularz rejestracyjny czy w inny sposób udostępnił nam swoje dane osobowe zapewniamy dostęp do dotyczących go danych w celu ich weryfikacji, modyfikacji lub też usunięcia. Podawanie danych osobowych jest dobrowolne</Text>
                </View> */}
                <div style={{ marginLeft: 14, marginTop: 10, marginRight: 14 }}>
                    <p style={{ fontSize: 11, fontWeight: '400', color:'grey' }}>3. iTech Mateusz Szmolke nie przekazuje, nie sprzedaje i nie użycza zgromadzonych danych osobowych użytkowników innym osobom lub instytucjom, chyba że dzieje się to za wyraźną zgodą lub na życzenie użytkownika, lub też na żądanie uprawnionych na podstawie prawa organów państwa na potrzeby prowadzonych przez nie postępowań.</p>
                </div>

                <div style={{ marginLeft: 14, marginTop: 40, marginRight: 14 }}>
                    <p style={{ fontSize: 13, fontWeight: '500', color:'grey' }}>II. Polityka w zakresie przechowywania informacji w urządzeniu końcowym użytkownika i uzyskiwania dostępu do informacji tam przechowywanych</p>
                </div>
                <div style={{ marginLeft: 14, marginTop: 10, marginRight: 14 }}>
                    <p style={{ fontSize: 11, fontWeight: '400', color:'grey' }}>1. W związku z udostępnianiem aplikacji mobilnych iTech Mateusz Szmolke przechowuje informacje w urządzeniu końcowym użytkownika i uzyskuje dostęp do informacji tam przechowywanych.</p>
                </div>
                <div style={{ marginLeft: 14, marginTop: 10, marginRight: 14 }}>
                    <p style={{ fontSize: 11, fontWeight: '400',color:'grey' }}>2. Działania, o których mowa w punkcie 1 wykonywane są w celu:</p>
                    <p style={{ fontSize: 11, fontWeight: '400', marginTop: 4, color:'grey' }}>a) optymalizacji korzystania z aplikacji mobilnych,</p>
                    <p style={{ fontSize: 11, fontWeight: '400', marginTop: 4, color:'grey' }}>b) tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy korzystają z aplikacji mobilnych, co umożliwia ulepszanie ich struktury i zawartości,</p>
                </div>


                <div style={{ marginLeft: 14, marginTop: 40, marginRight: 14 }}>
                    <p style={{ fontSize: 13, fontWeight: '500', color:'grey' }}>III. Kontakt</p>
                </div>
                <div style={{ marginLeft: 14, marginTop: 10, marginRight: 14 }}>
                    <p style={{ fontSize: 11, fontWeight: '400', color:'grey' }}>W przypadku pytań użytkowników dotyczących stosowanej przez iTech Mateusz Szmolke Polityki Prywatności, jak również w celu realizacji uprawnień określonych w punkcie I.3. prosimy o kontakt za pomocą poczty elektronicznej na adres: pomoc@investcalc.pl</p>
                </div>

            <div onClick={handleGoBack} style={{marginLeft:'4%', marginRight:'4%', cursor:'pointer'}} className={"login_press_button"}>
                <h4 style={{color:'white', paddingTop:'8px', paddingBottom:'8px'}}>Przeczytałem</h4>
            </div>

            </div>
        </div>
    )
}

export default PrivacyInfoView;
