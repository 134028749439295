import {getCurrentUser, logout} from "../actions/Users.action";
import {removeBuilding} from "../unitsOfWork/RemoveBuilding.unitOfWork";
import {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addBuilding} from "../unitsOfWork/AddBuilding.unitOfWork";
import {select} from "../slices/SelectedBuilding.slice";
import {select as selectCategory} from "../slices/SelectedCategory.slice";
import {useWatchUsers} from "../hooks/UseWatchUsers";
import {useNavigate} from "react-router-dom";

const MenuNavView = (props) => {

   
    const buildings = useSelector(state => state.buildings)
    const dispatch = useDispatch();
    const navigate = useNavigate();
 

    const signOut = () => {
        logout();
        navigate('/')
    }



    const handleAddBuilding = async () => {
        const name = prompt('Dodaj nazwę nowej inwestycji:')
        if (getCurrentUser().email === 'test@investcalc.pl') {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }
        else {
            if (name.length !== 0) {
                await addBuilding(name)
            }
    }

    }

    const selectBuilding = (id) => () => {
        dispatch(select(id));
        dispatch(selectCategory('ALL'))
        // setNavbarOpen(false)
        navigate('/')


    };
    useWatchUsers();



    return (
       
         <div ref={props.refOne} className={`menu-nav${props.navbarOpen ? ' show-menu' : ''}`}>
        
            <div className={'main_element_cost'}>
                <div>
                    <div style={{flex:1, display:'flex'}}>
                        <div style={{flex:0.7}}>
                            <h4>Moje inwestycje</h4>

                        </div>
                        <div style={{flex:0.3, justifyContent:"right",alignItems:"center", textAlign:"center", display:"flex"}}>
                            <img src={require('../assets/add.png')} height={15} onClick={handleAddBuilding}/>
                        </div>
                    </div>

                    {/*THIS*/}
                    {buildings &&
                        <div>
                            {buildings.slice().sort((a, b) => a.createDate > b.createDate ? 1 : -1).map(building =>
                                <div key={building.id} style={{ flex:1, display:"flex", alignItems:"center"}}>

                                    <div style={{flex: 0.8, flexDirection:"row"}} onClick={selectBuilding(building.id)}>
                                        <p style={{ color: 'green', fontSize:18 }}>{building.name}</p>
                                    </div>

                                    <div style={{ flex: 0.2, flexDirection:'row', right:'10%', position:'absolute'}}
                                         onClick={
                                             async () => {

                                                if (getCurrentUser().email === 'test@investcalc.pl')
                                                {
                                                    alert('Ta funkcja nie jest dostępna w wersji demo')
                                                }

                                                else {
                                                 if (buildings.length > 1) {
                                                     await removeBuilding(building.id);
                                                     const id = buildings.filter(x => x.id !== building.id)[0].id;
                                                     selectBuilding(id)()
                                                 }
                                                 else { alert('Nie można usunać inwestycji, przynajmniej jedna inwestycja musi istnieć'); }
                                             }
                                            }
                                         }
                                    >
                                        <img src={require('../assets/trash.png')} style={{ height: 18, width: 15 }} />
                                    </div>
                                </div>
                            )}

                        </div>
                    }
                </div>

                <div style={{textAlign:"center", justifyContent:"center", alignItems:"center", marginTop:'30px',flex:1 ,bottom:'15%'}}>
                    {/* <a style={{textDecoration:"none", color:"gray", cursor:'pointer'}} href='https://www.facebook.com/InvestCalc/'>Propozycja zmian</a>
                    <br/>
                    <hr style={{border:'1px solid #D0D0D0', width:'70%'}}/> */}

                    <a style={{textDecoration:"none", color:"gray", cursor:'pointer'}} href='mailto: pomoc@investcalc.pl'>Pomoc techniczna</a>
                    <p style={{color:'#880808', cursor:'pointer'}} onClick={signOut}>Wyloguj</p>
                </div>

            </div>
             </div>
          )
}

export default MenuNavView;
