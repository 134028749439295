import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { db } from "../firebase"; // Importuj zdefiniowany wcześniej obiekt Firebase
import { onValue, ref, off } from "firebase/database";
import { replaceCategories } from "../slices/Categories.slice";
import { replaceCosts } from "../slices/Costs.slice";

export const useWatchBuilding = (id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const buildingRef = ref(db, `/buildings/${id}`);

    const onDataChange = (snapshot) => {
      const value = snapshot.val();
      dispatch(replaceCosts(value?.costs));
      dispatch(replaceCategories(value?.categories));
    };

    // Dodajmy nasłuchiwanie na zmiany danych
    onValue(buildingRef, onDataChange);

    // Zwracamy funkcję, która usunie nasłuchiwanie po odmontowaniu komponentu
    return () => {
      off(buildingRef, "value", onDataChange);
    };
  }, [id, dispatch]);
};
