import {db} from "../firebase";
import {ref, set, remove} from 'firebase/database'


export const saveCost = (buildingId, cost) => {
    const costRef = ref(db, `/buildings/${buildingId}/costs/${cost.id}`);
    return set(costRef, cost);
}

export const saveCosts = (buildingId, costs) => {
    const costsRef = ref(db, `/buildings/${buildingId}/costs`);
    return set(costsRef, costs);
}

export const removeCost = (buildingId, id) => {
    const costRef = ref(db, `/buildings/${buildingId}/costs/${id}`);
    return remove(costRef);
}
