import '../styles/CategoryItem.style.css'
import { useLongPress } from 'use-long-press';
import {getCurrentUser} from "../actions/Users.action";


const CategoryItemComponent = (props) => {
    const { category, onSelect, onDelete, isActive } = props;


    const selectCategory = () => {
        onSelect(category)
    }


    const handleButtonPress = useLongPress(() => {
        if (getCurrentUser().email === 'test@investcalc.pl')
        {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }
        else {
        if(onDelete) onDelete(category);
        }
    });

   

    return (
        <div className={`category ${isActive ? 'category_active' : ''}`} onClick={selectCategory} {...handleButtonPress()}>
                <p className={`text_category ${isActive ? 'text_category_active' : ''}`}>{category.name}</p>
        </div>
    )
}

export default CategoryItemComponent;

