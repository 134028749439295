import { getDatabase, ref, set, remove, get } from 'firebase/database';
import db from '../firebase';

export const saveCategory = (buildingId, category) => {
    const database = getDatabase(db)
    const buildingRef = ref(database, `/buildings/${buildingId}/categories/${category.id}/`)
    return set(buildingRef, category);
}

export const saveCategories = (buildingId, categories) => {
    const database = getDatabase(db)
    const buildingRef = ref(database, `/buildings/${buildingId}/categories/`)
    return set(buildingRef,categories);
}

export const removeCategory = (buildingId, id) => {
    const database = getDatabase(db)
    const buildingRef = ref(database,`/buildings/${buildingId}/categories/${id}/`)
        return set(buildingRef,null);
}
