import '../styles/CategoryItem.style.css'
import { useLongPress } from 'use-long-press';


const ShowCategorySingleCategoryComponent = (props) => {
    const { category, isActive, categories } = props;


    return (
        <div className={'category_single_container'}>
            {isActive === true ?
            <div className={'single_category'}>
                <p className={'text_category'}>{category.name}</p>
            </div>
            :
            null    
            }
        </div>
    )
}

export default ShowCategorySingleCategoryComponent;

