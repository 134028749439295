import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    initialState: [],
    name: 'costs',
    reducers: {
        replaceCosts: (state, { payload }) => {
           return payload ? Object.values(payload) : []
        }
    }
});

export default slice.reducer;

export const { replaceCosts } = slice.actions;
