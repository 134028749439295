import CategoryView from '../components/ShowSingleElementCategory.component';
import LoaderHoc from '../components/Loader.component';
import FileItem from '../components/FileItem.component';
import { useSelector } from 'react-redux';
import '../styles/Build.style.css';
import '../styles/ShowSingleProduct.style.css';
import {getCurrentUser} from "../actions/Users.action";

import { updateCost } from '../unitsOfWork/UpdateCost.unitOfWork';
import { addFile } from '../unitsOfWork/AddFile.unitOfWork';
import { removeCost } from '../unitsOfWork/RemoveCost.unitOfWork';
import { openPicker } from '../utils/File.util';
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import CategoryItemComponent from "../components/CategoryItem.component";
import ShowCategorySingleCategoryComponent from '../components/ShowSingleElementCategory.component';

const validator = /^[+-]?\d*(?:[.,]\d*)?$/;


const ShowSingleProduct = ({props,route}) => {
    const {id} = useParams();
    const navigate = useNavigate();

    const cost = useSelector(state => state.costs.find(x => x.id === id));
    const [title, setTitle] = useState(cost?.title ?? '')
    const [price, setPrice] = useState(cost?.price ?? '')
    const [description, setDescription] = useState(cost?.description ?? '')
    const [categories, setCategories] = useState(cost?.categories ?? [])
    const allCategories = useSelector(state => state.categories.filter(x => x.id !== 'ALL'))
    const [errorMessage, setErrorMessage] = useState('');
    const [btnPopUpFile, setBtnPopUpFile] = useState(false);



    const handleSelectCategory = (status) => {
        if (categories.some(x => x === status.id)) {
            setCategories([...categories.filter(x => x !== status.id)])
        }
        else {
            setCategories([...categories, status.id])
        }
    }


   


    const edit = async () => {
        navigate(`/edit_products/${id}`)
    }

    const handleDelete = async () => {
        if (getCurrentUser().email === 'test@investcalc.pl')
        {
            alert('Ta funkcja nie jest dostępna w wersji demo')
        }
        else {
        await removeCost(cost.id);
        navigate('/')
        }
    }

    const handleClose = () => {
        navigate('/')
    }

    const handleEnablePopUp = () => {
        // event.defaultValue();
        setBtnPopUpFile(true)
    }



    useEffect(() => {
        if (cost) {
            setTitle(cost.title);
            setPrice(cost.price);
            setDescription(cost.description);
            setCategories(cost?.categories??[]);
        }

    }, [cost]);



    return (
       
        <div className={'main_element_cost'}>
        
                   

                    <div style={{flexDirection:'column'}}>
                         <h1 style={{color: 'green', marginBottom:'10px', marginTop:'20px'}}>{title}</h1>
                         <hr style={{border:'1px solid #D0D0D0', marginTop:'-10px'}}/>

                        <div>
                            <h3 style={{color: '#595858'}}>Cena:</h3>
                            <h2 className={'single_product_text'}>{price} zł</h2>
                        </div>

                        <div>
                        <div>
                            <h3 style={{color: '#595858'}}>Kategoria:</h3>
                        </div>
                        
                        {categories.length !==0 ?
                            <div className={"category_container"}>
                                {allCategories.slice().sort((a, b) => a.createDate > b.createDate ? 1 : -1).map(e => <ShowCategorySingleCategoryComponent
                                    key={e.id}
                                    category={e}
                                    isActive={categories.some(x => x === e.id)}
                                />)}
                                    
                            </div>
                            :
                            <h4 className={'single_product_text'}>brak przypisanej kategorii</h4>
                        }

                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Notatka:</h3>
                            {description.length === 0 ?
                                <h4 className={'single_product_description'}>brak notatki</h4>
                                :
                                <h4 className={'single_product_description'}>{description}</h4>

                            }
                        </div>

                        <div>
                            <h3 style={{color: '#595858'}}>Dokumentacja:</h3>
                        </div>

                        <div style={{ flexDirection: 'column' }}>
                          <h4 className={'single_product_description'}>Brak dokumentów</h4>


                            {/* <div style={{ flex: 1, marginRight: 7 }}>
                            {cost.files &&
                                     <div style={{ paddingTop: 10, marginRight: 3 }}>
                                         {cost.files.map(file =>
                                             <FileItem key={file.path} file={file} />)}
                                     </div>}
                            </div> */}
                        </div>

                        <div>
                            <p style={{color: '#B22222' }}>
                                {errorMessage}
                            </p>
                        </div>



                        <div className={'buttons_constainer'}>

                            <div className={'button_save'} onClick={edit}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '800'}}>Edytuj</p>
                            </div>

                            <div className={'button_delete'} onClick={handleDelete}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '800'}}>Usuń</p>
                            </div>

                            <div className={'button_cancel'} onClick={handleClose}>
                                <p style={{ color: 'white', paddingTop: 8, paddingBottom: 8, fontWeight: '800'}}>Wróć</p>
                            </div>
                        </div>

                    </div>
                </div>
               
    )
}

export default LoaderHoc(ShowSingleProduct);

