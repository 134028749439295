import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {store} from "./store/Main.store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
       <Provider store={store}>
               
       <BrowserRouter>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </BrowserRouter>
  </Provider>

);


reportWebVitals();


