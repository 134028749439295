import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {forgetPassword} from "../actions/Users.action";
import '../styles/Login.style.css'

const ForgetPasswordView = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('');


    const handleAlert = () => {

        alert("Informacja, sprawdź swoją skrzynkę i postępuj zgodnie z instrukacjami, aby zmienić hasło")
        navigate(-1)
    }

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleClickLogIn = () => {
        if (email.length > 0) {
            setErrorMessage('')
            forgetPassword(email)
                .then(() => handleAlert())
                .catch(_ => alert('Coś poszło nie tak, sprawdź wpisany adres e-mail.'));
        } else {
            setErrorMessage('Wpisz poprawny adres e-mail powiązany z Twoim kontem');
        }
    }

    return (

        <div className="main_view_on_login">
            <div style={{marginTop:'3%'}} className={"main_view_on_login_second"}>

                <div onClick={handleGoBack} style={{display: 'flex', alignItems: 'center', flex: 1, width: '100%', height: '90px', cursor:'pointer'}}>
                    <img style={{height: '18px'}} src={require('../assets/new_arrow.png')}/>
                    <p style={{color: 'dimgrey', marginLeft: '2px'}}>Wróć</p>
                </div>


                <div style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <img className={"logo_on_login"} src={require("../assets/Logo.png")}/>
                </div>

                <div style={{textAlign: 'center', marginBottom:'40px'}}>
                    <h2 style={{marginBottom: '-10px'}}>Zresetuj hasło</h2>
                    <p>Podaj swój adres e-mail na który otrzymasz link do wprowadzenia nowego hasła</p>
                </div>

                <div>
                 <h4 style={{color:'#595858'}}>E-mail</h4>
                    <input
                        placeholder={'e-mail'}
                        autoCapitalize={'none'}
                        className="input_popup"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </div>

                <div>
                    <p style={{color: '#B22222', fontWeight:'500'}}>
                        {errorMessage}
                    </p>
                </div>

                <div onClick={handleClickLogIn} className={"button_save_input"}>
                    <h4 style={{color: 'white', paddingTop: '8px', paddingBottom: '8px', fontWeight:'600'}}>Wyślij</h4>
                </div>

            </div>
        </div>
    )
}

export default ForgetPasswordView;
