import { getDatabase, ref, set, remove, get } from 'firebase/database';
import db from '../firebase';


// Przekazujemy referencję do bazy danych jako argument
export const getBuilding = (id) => {
    const database = getDatabase(db)

    const buildingRef = ref(database, `/buildings/${id}/`);
    return get(buildingRef);
}

export const getBuildings = (user) => {
    const database = getDatabase(db)

    const userBuildingsRef = ref(database, `/users/${user}/`);
    return get(userBuildingsRef);
}

export const saveBuilding = (user, building) => {
    const database = getDatabase(db)

    const userBuildingRef = ref(database, `/users/${user}/${building.id}/`);
    return set(userBuildingRef, building);
}

export const removeBuilding = (id) => {
    const database = getDatabase(db)

    const buildingRef = ref(database, `/buildings/${id}/`);
    return set(buildingRef, null);
}

export const removeUserBuilding = (userId, id) => {
    const database = getDatabase(db)

    const userBuildingRef = ref(database, `/users/${userId}/${id}/`);
    return set(userBuildingRef, null);
}
